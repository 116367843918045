import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import AuthenticationProvider from "./core/providers/AuthenticationProvider";
import NotificationsProvider from "./core/providers/NotificationsProvider";
import ConfirmationsProvider from "./core/providers/ConfirmationsProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import mainThemeLight from "./core/themes/main-light";
import Root from "./Root";
import { DateRangeProvider } from "./core/components/DateRangePicker";
import moment from "moment";

let from: Date = new Date(),
	to: Date = new Date();
// Fix time component from the range. set 12am for "from" date and 11:59pm for "to" date.
from = new Date(from.toDateString());
to = new Date(to.toDateString());
to.setDate(to.getDate() + 1);
to.setMilliseconds(-1);

export const DATE_RANGE_FOR_TODAY = { from, to };
export const DATE_RANGE_FOR_THIS_WEEK = {
	from: moment().startOf("week").toDate(),
	to: moment().endOf("week").toDate(),
};

function App() {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<AuthenticationProvider>
				<ThemeProvider theme={mainThemeLight}>
					<NotificationsProvider>
						<ConfirmationsProvider>
							<DateRangeProvider initialValue={DATE_RANGE_FOR_TODAY}>
								<CssBaseline />
								<Root />
							</DateRangeProvider>
						</ConfirmationsProvider>
					</NotificationsProvider>
				</ThemeProvider>
			</AuthenticationProvider>
		</MuiPickersUtilsProvider>
	);
}

export default App;
