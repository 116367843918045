import { Container, Grid, Tab, Tabs } from "@material-ui/core";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import SearchField from "../core/components/SearchField";
import VideoCard, { LoadingVideoCard } from "../core/components/video-card";
import TabPanel from "../core/components/tab-panel";
import useSearchFilter from "../core/hooks/use-search-filter";
import {
	generateSingleLabel,
	getAllTrainings,
	TrainingDocument,
} from "../core/system/litmon";
import useNotify from "../core/hooks/use-notify";
import SingleTrainingLabelDialog from "./TrainingLabelsGeneratorDialog/SingleTrainingLabelDialog";
import EmptyState from "../core/components/empty-state";
import VideoCallIcon from "@material-ui/icons/VideoCall";

function a11yProps(index: any) {
	return {
		id: `litmon-tab-${index}`,
		"aria-controls": `litmon-tabpanel-${index}`,
	};
}

export default function TrainingList({
	openTrainingModal,
	setrefetchList,
	refetchList,
}: {
	openTrainingModal: (
		trainingData: TrainingDocument,
		type: "edit" | "post"
	) => void;
	refetchList: number;
	setrefetchList: Dispatch<SetStateAction<number>>;
}) {
	const notify = useNotify();
	const [activeTab, setactiveTab] = useState("all");
	const [trainingList, setTrainingList] = useState<TrainingDocument[]>();
	const [searchTerm, setSearchTerm] = useState("");

	const searchedList = useSearchFilter(
		trainingList || [],
		"title, created_by",
		searchTerm
	);
	const [loadingList, setLoadingList] = useState(false);

	useLayoutEffect(() => {
		(async () => {
			try {
				setLoadingList(true);
				const res = await getAllTrainings();
				setTrainingList(res.filter(el => el.doc_id));
				setLoadingList(false);
				setrefetchList(0);
			} catch (err: any) {
				notify(err);
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notify, refetchList]);

	const [openLabelDialog, setopenLabelDialog] = useState(false);
	const [pdfUrl, setPdfUrl] = useState<string | null>(null);
	const [pdfLoading, setpdfLoading] = useState(false);
	const [selectedDocId, setselectedDocId] = useState<string | null>(null);

	const handleGenerateLabel = async (title: string, docid: string) => {
		try {
			setpdfLoading(true);
			setselectedDocId(docid);
			setopenLabelDialog(true);
			const pdfUrl: string = await generateSingleLabel({
				title,
				docid,
			});
			setPdfUrl(pdfUrl);
		} catch (err) {
			alert((err as Error).message);
		} finally {
			setpdfLoading(false);
		}
	};

	return (
		<Container>
			<Grid
				style={{
					display: "flex",
					justifyContent: "space-between",
					gap: "1rem",
					flexWrap: "wrap",
					marginBottom: "1rem",
				}}
			>
				<Grid item xs={12} md={5}>
					<Tabs
						value={activeTab}
						onChange={(_, newValue: string) => {
							setactiveTab(newValue);
						}}
						indicatorColor="secondary"
						textColor="secondary"
						aria-label="full width tabs example"
					>
						<Tab label="Organization Videos" value="all" {...a11yProps(0)} />
						<Tab label="Momenttrack Videos" value="momenttrack" {...a11yProps(1)} />
					</Tabs>
				</Grid>
				<Grid item xs={12} md={5}>
					<SearchField
						value={searchTerm}
						onChange={ev => setSearchTerm(ev.target.value)}
						disabled={loadingList}
					/>
				</Grid>
			</Grid>
			<TabPanel value={activeTab} keyValue="all">
				<Grid container spacing={2}>
					{!loadingList && trainingList ? (
						searchedList.length > 0 ? (
							searchedList.map((el, i) => (
								<Grid key={`${el.doc_id}_${i}`} item xs={12} sm={6} md={4}>
									<VideoCard
										setrefetchList={setrefetchList}
										genenerateLabel={(title, docid) => handleGenerateLabel(title, docid)}
										openTrainingModal={openTrainingModal}
										item={el}
									/>
								</Grid>
							))
						) : (
							<EmptyState
								icon={<VideoCallIcon fontSize="large" color="secondary" />}
								title="No Videos Found"
							/>
						)
					) : (
						Array(3)
							.fill("")
							.map((el, i) => (
								<Grid key={`loading_card_${i}`} item xs={12} sm={6} md={4}>
									<LoadingVideoCard />
								</Grid>
							))
					)}
				</Grid>
			</TabPanel>
			{/* TODO: Momenttrack videos will be hardcorded */}
			<TabPanel value={activeTab} keyValue="momenttrack">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<EmptyState
							icon={<VideoCallIcon fontSize="large" color="secondary" />}
							title="Momenttrack videos coming soon"
						/>
					</Grid>
				</Grid>
			</TabPanel>

			<SingleTrainingLabelDialog
				docid={selectedDocId || ""}
				pdfUrl={pdfUrl || ""}
				open={openLabelDialog}
				onClose={(ev, reason) => {
					if (reason !== "backdropClick") {
						setopenLabelDialog(false);
					}
				}}
				isLoading={pdfLoading}
				disableEscapeKeyDown
				maxWidth="sm"
				fullWidth
			/>
		</Container>
	);
}
