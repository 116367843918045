import React from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { visit3hd } from "../../subapp/utils";
import { LinearProgress, Typography } from "@material-ui/core";

const SingleTrainingLabelDialog: React.FC<
	DialogProps & { pdfUrl: string; docid: string; isLoading?: boolean }
> = props => {
	const { pdfUrl, isLoading, docid, ...otherProps } = props;
	return (
		<Dialog {...otherProps} maxWidth={!pdfUrl ? props.maxWidth : "md"}>
			<DialogTitle>Print Training Label</DialogTitle>
			<DialogContent>
				{isLoading ? (
					<>
						<LinearProgress />
						<Typography style={{ marginTop: "1rem" }} align="center">
							Loading Content...
						</Typography>
					</>
				) : (
					!!pdfUrl && (
						<iframe
							title="Training QRs (empty)"
							src={props.pdfUrl}
							width="100%"
							style={{ border: "none", height: "70vh" }}
						/>
					)
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					type="button"
					onClick={() => props.onClose && props.onClose({}, "escapeKeyDown")}
					color="secondary"
				>
					Close
				</Button>
				<Button
					variant="contained"
					color="secondary"
					type="button"
					onClick={() => visit3hd(`/${docid}`)}
				>
					Open Training Video
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default SingleTrainingLabelDialog;
