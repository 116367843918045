import React from "react";
import { StepsInputProps } from "./types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";

const StepsInput: React.FC<StepsInputProps> = props => {
	const {
		value: steps = [],
		onChange = () => null,
		maxSteps = Number.POSITIVE_INFINITY,
		disabled = false,
	} = props;

	const onChangeHandler = (index: number) => (ev: any) => {
		steps[index] = ev.target.value;
		onChange([...steps]);
	};

	const handleAddStep = () => {
		if (steps.length >= maxSteps) return;

		onChange([...steps, ""]);
	};

	const removeStepHandler = (index: number) => () => {
		steps.splice(index, 1);
		onChange([...steps]);
	};

	return (
		<Grid container direction="column" spacing={3}>
			{steps?.map((step, index) => (
				<Grid key={`step-${index + 1}`} item>
					<TextField
						label={`Step #${index + 1}`}
						value={step}
						onChange={onChangeHandler(index)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="medium"
										onClick={removeStepHandler(index)}
										disabled={disabled}
										edge="end"
									>
										<Icon>delete</Icon>
									</IconButton>
								</InputAdornment>
							),
						}}
						color="secondary"
						fullWidth
						multiline
						disabled={disabled}
					/>
				</Grid>
			))}
			<Grid item>
				<Fab
					color="secondary"
					className="add-btn"
					onClick={handleAddStep}
					disabled={disabled}
				>
					<Icon>add</Icon>
				</Fab>
			</Grid>
		</Grid>
	);
};

export default StepsInput;
