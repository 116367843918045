import { useFormik, FormikConfig } from "formik";
import * as Yup from "yup";

export interface TrainingInput {
	title: string;
	description?: string;
	instructions?: string;
	video_url: string;
	email?: string;
	thumbnail_url?: string;
}

export type TrainingInputFormConfig = Omit<
	FormikConfig<Partial<TrainingInput>>,
	"validationSchema" | "initialValues"
> & { initialValues?: Partial<TrainingInput> };

export const validationSchema = Yup.object({
	title: Yup.string().trim().required("Required."),
	description: Yup.string().trim().nullable(),
	email: Yup.string().trim().nullable(),
	instructions: Yup.array().of(Yup.string()).notRequired(),
	video_url: Yup.string().required("Training video is required."),
});

const DEFAULT_TRAINING_INPUT: TrainingInput = {
	title: "",
	description: "",
	instructions: "[]",
	video_url: "",
	email: "",
	thumbnail_url: "",
};

const useTrainingInputForm = ({
	initialValues,
	...formikConfig
}: TrainingInputFormConfig) => {
	return useFormik({
		initialValues: Object.assign({}, DEFAULT_TRAINING_INPUT, initialValues),
		validationSchema,
		...formikConfig,
	});
};

export default useTrainingInputForm;
