import React from "react";
import { PersonFormProps, PersonFormValues } from "./types";
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import validationSchema from "./validation-schema";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

const defaultValues = {
	first_name: "",
	last_name: "",
	phone: "",
	email: "",
	shift: "NA",
};

const PersonForm: React.FC<PersonFormProps> = props => {
	const { initialValues = {}, onSubmit = () => {}, disabled } = props;

	const formik = useFormik<PersonFormValues>({
		initialValues: {
			first_name: initialValues.first_name || defaultValues.first_name,
			last_name: initialValues.last_name || defaultValues.last_name,
			phone: initialValues.phone || defaultValues.phone,
			email: initialValues.email || defaultValues.email,
			shift: initialValues?.shift || defaultValues.shift,
		},
		validationSchema,
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<TextField
						label="First name"
						fullWidth
						variant="outlined"
						color="secondary"
						disabled={disabled}
						{...formik.getFieldProps("first_name")}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Last name"
						fullWidth
						variant="outlined"
						color="secondary"
						disabled={disabled}
						{...formik.getFieldProps("last_name")}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Email"
						fullWidth
						variant="outlined"
						color="secondary"
						disabled={disabled}
						{...formik.getFieldProps("email")}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Phone"
						fullWidth
						variant="outlined"
						color="secondary"
						disabled={disabled}
						{...formik.getFieldProps("phone")}
					/>
				</Grid>
				<Grid item>
					<FormControl fullWidth variant="outlined">
						<InputLabel
							style={{ background: "white", padding: "0 0.4rem" }}
							shrink={true}
							color="secondary"
							id={"shift"}
						>
							Shift
						</InputLabel>
						<Select
							disableUnderline
							fullWidth
							disabled={disabled}
							defaultValue={"NA"}
							color="secondary"
							{...formik.getFieldProps("shift")}
						>
							<MenuItem selected value="NA">
								N/A
							</MenuItem>
							<MenuItem value="MORNING">Morning</MenuItem>
							<MenuItem value="DAY">Day</MenuItem>
							<MenuItem value="EVENING">Evening</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item>{props.children}</Grid>
			</Grid>
		</form>
	);
};

export default PersonForm;
