import React, {
	useState,
	useImperativeHandle,
	useRef,
	forwardRef,
} from "react";
import { BinAndBinFamilyType, BinDialogProps, BinDialogRef } from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import BinForm, { BinFormRef, BinData } from "../core/forms/BinForm";
import useNotify from "../core/hooks/use-notify";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { visit3hd } from "../subapp/utils";

const BinDialog: React.ForwardRefRenderFunction<BinDialogRef, BinDialogProps> =
	function (props, ref) {
		const {
			title = "Bin",
			primaryActionText = "Done",
			secondaryActionText = "Close",
			primaryAction = () => {},
			secondaryAction = () => {},
		} = props;

		const [data, setData] = useState<BinAndBinFamilyType>();
		const binForm = useRef<BinFormRef>(null);
		const notify = useNotify();
		const [loading, setLoading] = useState(false);
		const [open, setOpen] = useState(false);
		const [editable, setEditable] = useState(false);

		async function handlePrimaryAction(data: BinData) {
			try {
				setLoading(true);
				await primaryAction(data);
				setOpen(false);
			} catch (err) {
				notify(err as Error);
			} finally {
				setLoading(false);
			}
		}

		async function handleSecondaryAction() {
			try {
				setLoading(true);
				await secondaryAction();
				setOpen(false);
			} catch (err) {
				notify(err as Error);
			} finally {
				setLoading(false);
			}
		}

		useImperativeHandle(
			ref,
			() => {
				function openDialog(editable = false, data?: BinAndBinFamilyType) {
					setEditable(editable);
					setOpen(true);
					setData(data);
				}

				return { openDialog };
			},
			[]
		);

		return (
			<Dialog
				open={open}
				fullWidth
				maxWidth={"sm"}
				disableEscapeKeyDown={loading}
				onClose={() => setOpen(false)}
			>
				<DialogTitle>{title}</DialogTitle>
				{!editable && <Divider />}
				<DialogContent>
					<Box pt={2} pb={4}>
						{editable && (
							<BinForm
								ref={binForm}
								initialValues={data?.bin}
								onSubmit={handlePrimaryAction}
								disabled={loading}
							/>
						)}

						{!editable && !!data && (
							<Grid container spacing={3}>
								<Grid item xs={6} sm={4}>
									<Typography variant="caption" color="textSecondary">
										BIN FAMILY
									</Typography>
									<Typography variant="body1">{data?.bin?.name}</Typography>
								</Grid>

								<Grid item xs={6} sm={4}>
									<Typography variant="caption" color="textSecondary">
										PRODUCT
									</Typography>
									<Link
										component={RouterLink}
										to={`/products/${data?.binFamily?.product_id}`}
										color="textPrimary"
										underline="always"
										variant="body1"
										display="block"
										noWrap
									>
										{data?.binFamily?.product.name}
									</Link>
								</Grid>

								<Grid item xs={6} sm={4}>
									<Typography variant="caption" color="textSecondary">
										PART #
									</Typography>
									<Typography variant="body1">
										{data?.binFamily?.product.part_number}
									</Typography>
								</Grid>

								<Grid item xs={6} sm={4}>
									<Typography variant="caption" color="textSecondary">
										VENDOR
									</Typography>
									<Typography variant="body1">
										{data?.binFamily?.preferred_vendor.name}
									</Typography>
								</Grid>

								<Grid item xs={6} sm={4}>
									<Typography variant="caption" color="textSecondary">
										DEFAULT QTY.
									</Typography>
									<Typography variant="body1">
										{data?.binFamily?.default_quantity}
									</Typography>
								</Grid>

								<Grid item xs={6} sm={4}>
									<Typography variant="caption" color="textSecondary">
										PLACE
									</Typography>
									<Link
										onClick={() => visit3hd(`/${data?.binFamily?.location.beacon_id}`)}
										target="_BLANK"
										color="textPrimary"
										underline="always"
										variant="body1"
										display="block"
									>
										{data?.binFamily?.location.name}
									</Link>
								</Grid>
							</Grid>
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					{loading && <CircularProgress size={24} color="primary" />}

					<Button disabled={loading} onClick={handleSecondaryAction}>
						{secondaryActionText}
					</Button>

					{editable && (
						<Button
							disabled={loading}
							variant="contained"
							color="primary"
							onClick={() => binForm.current?.submitForm()}
						>
							{primaryActionText}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	};

export default forwardRef(BinDialog);
