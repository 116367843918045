import React, { useState } from "react";
import { PasswordFormProps, PasswordFormValues } from "./types";
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import validationSchema from "./validation-schema";

const defaultValues = {
	password: "",
	current_password: "",
	confirm_password: "",
};

const PasswordForm: React.FC<PasswordFormProps> = props => {
	const { initialValues = {}, onSubmit = () => {}, disabled } = props;

	const [showPass, setShowPass] = useState(false);

	const formik = useFormik<PasswordFormValues>({
		initialValues: {
			password: initialValues.password || defaultValues.password,
			current_password: defaultValues.current_password,
			confirm_password: defaultValues.confirm_password,
		},
		validationSchema,
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Grid container direction="column" spacing={2}>
				<Grid item>
					<TextField
						label="New password"
						type={showPass ? "text" : "password"}
						fullWidth
						variant="outlined"
						color="secondary"
						disabled={disabled}
						{...formik.getFieldProps("password")}
						error={!!formik.errors["password"]}
						helperText={formik.errors["password"]}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPass(currVal => !currVal)}
										edge="end"
									>
										<Icon>{showPass ? "visibility_off" : "visibility"}</Icon>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item>
					<TextField
						label="Confirm password"
						type={showPass ? "text" : "password"}
						fullWidth
						variant="outlined"
						color="secondary"
						disabled={disabled}
						{...formik.getFieldProps("confirm_password")}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPass(currVal => !currVal)}
										edge="end"
									>
										<Icon>{showPass ? "visibility_off" : "visibility"}</Icon>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item>{props.children}</Grid>
			</Grid>
		</form>
	);
};

export default PasswordForm;
