import { Button, IconButton, TextField } from "@material-ui/core";
import { Dispatch, SetStateAction } from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import { formatNumber } from "./min-max-field";

interface PropTypes {
	fixedMax: number;
	plotLines: { value: number; label: string }[];
	setplotLines: Dispatch<SetStateAction<{ value: number; label: string }[]>>;
}
export default function PlotLineInput({
	fixedMax,
	plotLines,
	setplotLines,
}: PropTypes) {
	return (
		<div>
			<label htmlFor="">Add Extra Plot Line</label>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "0.5rem",
					flexWrap: "wrap",
				}}
			>
				{plotLines.map((el, i) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "0.5rem",
						}}
						key={`plot_field_${i}`}
					>
						<TextField
							type="text"
							variant="outlined"
							color="secondary"
							size="small"
							style={{
								flex: "1",
							}}
							onChange={e => {
								setplotLines(prev => {
									const newValues = [...prev];
									newValues[i] = {
										label: e.target.value,
										value: newValues[i]?.value || 0,
									};
									return newValues;
								});
							}}
							value={el.label}
							required={false}
							placeholder="Plot name"
						/>
						<TextField
							type="text"
							variant="outlined"
							color="secondary"
							size="small"
							style={{
								flex: "1",
							}}
							onChange={e => {
								const inputValue = e.target.value.replace(/,/g, ""); // Remove formatting
								const numericValue = parseFloat(inputValue); // Parse as a number

								if (!isNaN(numericValue) || inputValue === "") {
									// Update state with unformatted numeric value
									setplotLines(prev => {
										const newValues = [...prev];
										newValues[i] = {
											value: numericValue || 0,
											label: newValues[i]?.label || "",
										}; // Default to 0 if input is empty
										return newValues;
									});
								}
							}}
							value={formatNumber(el.value)}
							InputProps={{
								inputProps: { min: 0, max: fixedMax, step: 1 },
							}}
							required={false}
							placeholder="plot value"
						/>
						<IconButton
							style={{ width: "max-content" }}
							onClick={() =>
								setplotLines(prev => {
									const newVals = [...prev];
									newVals.splice(i, 1); // Remove the item
									return newVals;
								})
							}
							size="small"
							aria-label="remove item"
						>
							<RemoveIcon style={{ marginRight: 0 }} />
						</IconButton>
					</div>
				))}

				<Button
					variant="outlined"
					// style={{ width: "max-content" }}
					color="secondary"
					onClick={() => setplotLines(prev => [...prev, { label: "", value: 0 }])}
				>
					Add
				</Button>
			</div>
		</div>
	);
}
