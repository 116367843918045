import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Box from "@material-ui/core/Box";
import RolesProvider from "./core/providers/RolesProvider";
import PersonsProvider from "./core/providers/PersonsProvider";
import ProductsProvider from "./core/providers/ProductsProvider";
import PlacesProvider from "./core/providers/PlacesProvider";
import { useAuthentication } from "./core/providers/AuthenticationProvider";
import ProtectedRoute from "./core/components/ProtectedRoute";
import MainLayout from "./core/components/MainLayout";
import Login from "./Login";
import AcceptInvite from "./AcceptInvite";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Settings from "./Settings";
import ProductsLookup from "./ProductsLookup";
import Places from "./Places";
import Products from "./Products";
import ProductionOrders from "./ProductionOrders";
import Persons from "./Persons";
import Dashboard from "./Dashboard";
import Reports from "./Reports";
import LearningInTheMoment from "./LearningInTheMoment";
import TemplateBuilder from "./subapp/TemplateBuilder";
import ViewContainers from "./Containers";
import { IS_DEV_MODE } from "./subapp/utils";
import ProductPage from "./subapp/ProductPage";
import VendorsProvider from "./core/providers/VendorsProvider";
import VendorsPage from "./VendorsPage";
import BinFamiliesProvider from "./core/providers/BinFamiliesProvider";
import BinFamiliesPage from "./BinFamiliesPage";
import ShoppingList from "./ShoppingList";
import OrderPlates from "./OrderPlates";
import IAmEmptyPage from "./ScanBin/IAmEmptyPage";
import CustomOrderPage from "./ScanBin/CustomOrderPage";
import ScanBin from "./ScanBin";
import BinFamilyPage from "./BinFamilyPage";
import PublicQuantityReport from "./Dashboard/ReportsSection/StationReportCard/PublicQuantityReport";
import EmbedDemo from "./Dashboard/ReportsSection/StationReportCard/EmbedDemo";

const ShoppingListPage = () => <ShoppingList type="added" />;
const ShoppingListHistory = () => <ShoppingList type="deleted" />;

const Root: React.FC = () => {
	const isAuthenticated = !!useAuthentication()[0];

	return (
		<Box height="100vh">
			<BrowserRouter>
				<Switch>
					<ProtectedRoute
						allow={!isAuthenticated}
						redirectPath=""
						path="/login"
						component={Login}
					/>

					<ProtectedRoute
						allow={!isAuthenticated}
						redirectPath=""
						path="/forgot-password"
						component={ForgotPassword}
					/>

					<ProtectedRoute
						allow={!isAuthenticated}
						redirectPath=""
						path="/accept-invite"
						component={AcceptInvite}
					/>
					{IS_DEV_MODE && (
						<ProtectedRoute
							exact
							allow={isAuthenticated}
							redirectPath="/login"
							path="/template-builder/:orgId/:productId/:orderId"
							component={TemplateBuilder}
						/>
					)}

					<ProtectedRoute
						allow={!isAuthenticated}
						redirectPath=""
						path="/reset-password"
						component={ResetPassword}
					/>
					<Route exact path="/customer-template" component={ProductPage} />
					<Route
						exact
						path="/quantity-report/:id"
						component={PublicQuantityReport}
					/>
					<Route exact path="/embed-demo" component={EmbedDemo} />
					<ProtectedRoute allow={isAuthenticated} redirectPath="/login">
						<MainLayout>
							<ProductsProvider>
								<VendorsProvider>
									<BinFamiliesProvider>
										<PlacesProvider>
											<RolesProvider>
												<PersonsProvider>
													<Switch>
														<Route path="/dashboard" component={Dashboard} />
														<Route
															path="/reports/:placeId?/:reportType?"
															component={Reports}
														/>

														<Route path="/settings" component={Settings} />
														<Route path="/products-lookup" component={ProductsLookup} />
														<Route path="/places" component={Places} />
														<Route path="/products" component={Products} />
														<Route path="/vendors" component={VendorsPage} />
														<Route path="/scan-bin" component={ScanBin} />
														<Route
															path="/bin-families/:binFamilyId"
															component={BinFamilyPage}
														/>
														<Route path="/bin/empty" component={IAmEmptyPage} />
														<Route path="/bin/custom-order" component={CustomOrderPage} />
														<Route path="/order-plates" component={OrderPlates} />
														<Route
															exact
															path="/shopping-lists"
															component={ShoppingListPage}
														/>
														<Route
															path="/shopping-lists/history"
															exact
															component={ShoppingListHistory}
														/>
														<Route path="/bin-families" component={BinFamiliesPage} />
														<Route path="/orders" component={ProductionOrders} />
														<Route path="/stacks" component={ViewContainers} />
														<Route path="/persons" component={Persons} />
														<Route path="/litmon" component={LearningInTheMoment} />
														<Redirect to="/reports" />
													</Switch>
												</PersonsProvider>
											</RolesProvider>
										</PlacesProvider>
									</BinFamiliesProvider>
								</VendorsProvider>
							</ProductsProvider>
						</MainLayout>
					</ProtectedRoute>
				</Switch>
			</BrowserRouter>
		</Box>
	);
};

export default Root;
