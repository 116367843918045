import { TrainingDocument, TrainingInputData } from "./types";
import randomize from "randomatic";
import { subscribe } from "../pubsub";
import html2Pdf, { PdfOptions } from "../utils/html-to-pdf";
import labelTemplate from "./label-templates/training-labels-sheet";
import singleLabelTemplate, {
	SingleLabelInfo,
} from "./label-templates/training-label-single";
import { generateIds } from "../utils/parse-momenttrack-id";
import client from "../ordertrack-client";

// const FIREBASE_BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL;
const authData = {
	org_slug: "",
};

subscribe("update:authentication", ({ data }) => {
	Object.assign(authData, data || { org_slug: "" });
});

const labelPdfOptions: PdfOptions = {
	pdf_mode: "portrait",
	pdf_name: `training-labels-${randomize("a0", 12)}`,
	page_size: "Letter",
	margin_top: "0mm",
	margin_bottom: "0mm",
	margin_left: "0mm",
	margin_right: "0mm",
	disable_smart_shrinking: null,
};
const singlelabelPdfOptions: PdfOptions = {
	pdf_mode: "portrait",
	pdf_name: `single-training-label-${randomize("a0", 12)}`,
	margin_top: "0mm",
	margin_bottom: "0mm",
	margin_left: "0mm",
	margin_right: "0mm",
	page_height: "155mm",
	page_width: "90mm",
	disable_smart_shrinking: null,
};

export const generateSingleLabel = (doc: SingleLabelInfo) => {
	return html2Pdf(singleLabelTemplate(doc), {
		...singlelabelPdfOptions,
		pdf_name: `${doc.title}-training-label`,
	});
};

export const generateTrainingQrs = (qty = 30) => {
	const emptyDocs = generateIds("document", qty).map(docid => ({
		docid,
		training_versions: [
			{
				title: "",
			},
		],
		curr_version_index: 0,
	}));

	return html2Pdf(labelTemplate(emptyDocs), {
		...labelPdfOptions,
		pdf_name: `${qty}-training-labels-empty`,
	});
};

export const lookupTraining = async (
	docid: string
): Promise<TrainingDocument> => {
	const res = await client.get(`lim/${docid}`);
	if (!res.data?.data) throw new Error("Training not found.");
	return res.data?.data;
};

export const saveTraining = async (
	trainingData: TrainingInputData,
	type: "edit" | "post"
) => {
	const newTraining: TrainingInputData = {
		pick_type: "TRAINING",
		doc_id: trainingData.doc_id,
		email: trainingData.email,
		thumbnail_url: trainingData.thumbnail_url,
		video_url: trainingData.video_url,
		visibility: trainingData.visibility,
		title: trainingData.title,
		instructions: JSON.stringify(trainingData.instructions),
		// there's currently no field on the ui for description but API requires it
		description: "",
	};

	// Patch document to firebase.
	if (type === "post") {
		await client.post(`lim`, newTraining);
	} else {
		const { doc_id, ...data } = newTraining;
		await client.put(`lim/${doc_id}`, data);
	}
	return newTraining;
};

export async function getAllTrainings(): Promise<TrainingDocument[]> {
	const res = await client.get(`/lim`);
	return res.data?.data;
}
