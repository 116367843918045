interface TabPanelProps {
	children?: React.ReactNode;
	keyValue: string;
	value: string;
}

export default function TabPanel(props: TabPanelProps) {
	const { children, value, keyValue, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== keyValue}
			id={`scrollable-auto-tabpanel-${keyValue}`}
			aria-labelledby={`scrollable-auto-tab-${keyValue}`}
			{...other}
		>
			{value === keyValue && children}
		</div>
	);
}
