import apiClient from "../ordertrack-client";
import { LicensePlate } from "./types";

export const lookupLicensePlates = async (
	value: string,
	searchBy: "external_serial_number" | "part_number" | "lp_id" | string
): Promise<LicensePlate[]> => {
	const res = await apiClient.get("/license_plates/lookup", {
		params: { [searchBy]: value },
	});
	return res.data.data;
};
