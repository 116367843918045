import React from "react";
import { PanelTabNames } from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Layout, { Slot, Header, Content } from "../core/components/Layout";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Switch, Route, Redirect, Link as RouterLink } from "react-router-dom";
import { StationHealthReport } from "../core/system/reports";
import StationReportsProvider from "../core/providers/StationReportsProvider";
import StationOverview from "./StationOverview";
import FirstTimeRightReport from "./FirstTimeRightReport";
import DateRangePicker, {
	DateRangeProvider,
} from "../core/components/DateRangePicker";
import { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import usePlaces from "../core/hooks/use-places";
import moment from "moment/moment";
import { getStationHealthReport } from "../core/system/reports";
import styles from "./styles";
import LogsTable from "./LogsTable";
import usePagination from "../core/hooks/use-pagination";
import { useAuthentication } from "../core/providers/AuthenticationProvider";
import SendCSVModal from "../core/components/SendCSVModal";
import { DATE_RANGE_FOR_THIS_WEEK } from "../App";

const useStyles = makeStyles(styles);

const Reports: React.FC = () => {
	const classes = useStyles();
	const { placeId, reportType } = useParams<{
		placeId?: string;
		reportType?: PanelTabNames;
	}>();
	const history = useHistory();
	const [places] = usePlaces();
	const [reportsRecord, setReportsRecord] = useState<{
		[placeId: string]: StationHealthReport;
	}>({});
	const [loadingReport, setLoadingReport] = useState(false);
	const [viewPersonal, setViewPersonal] = useState(false);
	const [
		page,
		availablePages,
		loadingLogs,
		setPage,
		setLoadingLogs,
		getAvailablePages,
		handleLoadMore,
	] = usePagination();
	const tabIndex = (() => {
		switch (reportType) {
			case "first-time-right":
				return 1;
			case "overview":
			default:
				return 0;
		}
	})();

	const selectedPlace = useMemo(() => {
		if (!!placeId) return places.find(p => p.id === +placeId) || null;
		return null;
	}, [places, placeId]);

	const hasReportData =
		!!placeId &&
		!Number.isNaN(placeId) &&
		!loadingReport &&
		!!reportsRecord[+placeId];

	const logs = useMemo(() => {
		if (!placeId) return [];

		const reportData = reportsRecord[+placeId];
		return (reportData?.logs || []).sort(
			(a, b) =>
				moment.utc(b.arrived_at).valueOf() - moment.utc(a.arrived_at).valueOf()
		);
	}, [reportsRecord, placeId]);

	useEffect(() => {
		// If report data doesnot exist, fetch.
		if (!!placeId && !reportsRecord[+placeId]) {
			setPage(1);
			setLoadingReport(true);

			getStationHealthReport(+placeId, { page: 1 })
				.then(rep => {
					if (!rep.location_id) {
						throw new Error(`No data for this place`);
					}
					setReportsRecord(rec => ({ ...rec, [rep.location_id]: rep }));
					setLoadingReport(false);

					getAvailablePages(rep.logs_total);
				})
				.catch(err => {
					alert((err as Error).message);
					setLoadingReport(false);
				});
		}
		// eslint-disable-next-line
	}, [placeId]);

	useEffect(() => {
		// If page was changed, fetch.
		if (page === 1) return;
		if (!!placeId) {
			setLoadingLogs(true);
			getStationHealthReport(+placeId, { page })
				.then(rep => {
					setReportsRecord(rec => {
						const previousLogs = rec[rep.location_id].logs;
						return {
							...rec,
							[rep.location_id]:
								rep.logs && previousLogs
									? {
											...rec[rep.location_id],
											logs: [...previousLogs, ...rep.logs],
									  }
									: { ...rec[rep.location_id], logs: rep.logs },
						};
					});
					setLoadingLogs(false);

					getAvailablePages(rep.logs_total);
				})
				.catch(err => {
					alert((err as Error).message);
					setLoadingLogs(false);
				});
		}
		// eslint-disable-next-line
	}, [page]);

	// generate personal report csv
	const [currPerson] = useAuthentication();
	const [openMailModal, setopenMailModal] = useState(false);

	return (
		<StationReportsProvider reportsData={reportsRecord}>
			<Layout>
				<Slot name="main-toolbar">
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<Typography>Reports</Typography>
						</Grid>
						{(hasReportData || loadingReport) && (
							<>
								<Grid item>
									<Autocomplete
										id="station-selector"
										options={places}
										getOptionLabel={option => option.name}
										value={selectedPlace}
										onChange={(ev, val) => {
											// navigate to place id
											if (!!val) history.push(`/reports/${val.id}/${reportType || ""}`);
										}}
										getOptionSelected={(opt, val) => val.id === opt.id}
										disabled={loadingReport}
										renderInput={params => (
											<TextField
												{...params}
												label="Place"
												variant="outlined"
												size="small"
												className={classes.dropdown}
											/>
										)}
									/>
								</Grid>
							</>
						)}
						<Grid item xs />

						<Grid item>
							<DateRangePicker />
						</Grid>
					</Grid>
				</Slot>

				{hasReportData && (
					<Header>
						<Container maxWidth={false}>
							<Tabs
								value={tabIndex}
								variant="scrollable"
								scrollButtons="auto"
								className={classes.tabs}
							>
								<Tab
									label="Overview"
									component={RouterLink}
									to={`/reports/${placeId}/overview`}
									classes={{
										root: classes.tab,
										selected: classes.selectedTab,
									}}
								/>
								<Tab
									label="First Time Right"
									component={RouterLink}
									to={`/reports/${placeId}/first-time-right`}
									classes={{
										root: classes.tab,
										selected: classes.selectedTab,
									}}
								/>
							</Tabs>
						</Container>
					</Header>
				)}
				<Content>
					<Container maxWidth={false}>
						{!hasReportData && !loadingReport && (
							<Box textAlign="center" py={5}>
								<Container maxWidth="xs">
									<Typography variant="h5" gutterBottom>
										Select place
									</Typography>
									<Autocomplete
										id="station-selector"
										options={places}
										getOptionLabel={option => option.name}
										value={selectedPlace}
										onChange={(ev, val) => {
											// navigate to place id
											if (!!val) history.push(`/reports/${val.id}/${reportType || ""}`);
										}}
										getOptionSelected={(opt, val) => val.id === opt.id}
										disabled={loadingReport}
										renderInput={params => (
											<TextField
												{...params}
												placeholder="Search..."
												variant="outlined"
												className={classes.dropdown}
											/>
										)}
									/>

									<Box py={5}>
										<Typography
											variant="caption"
											color="textSecondary"
											component="p"
											gutterBottom
										>
											<i>(or)</i>
										</Typography>
										<div>
											<Button
												onClick={() => setopenMailModal(true)}
												size="large"
												color="primary"
												variant="contained"
											>
												View everything report
											</Button>
											{currPerson && (
												<Button
													onClick={() => setViewPersonal(true)}
													size="large"
													color="secondary"
													style={{ marginTop: "1rem" }}
													variant="contained"
												>
													View Personal Productivity report
												</Button>
											)}
										</div>
									</Box>
									<SendCSVModal
										openModal={openMailModal}
										url="/reports/everything_report"
										setOpenModal={setopenMailModal}
									/>
									<SendCSVModal
										passDateRange
										openModal={viewPersonal}
										url="/users/report"
										setOpenModal={setViewPersonal}
									/>
								</Container>
							</Box>
						)}
						{loadingReport && (
							<Box textAlign="center" py={10}>
								<Typography gutterBottom>Loading report data...</Typography>
								<CircularProgress size={24} />
							</Box>
						)}
						{hasReportData && !!placeId && (
							<Paper>
								<Switch>
									<Route path="/reports/:placeId?/first-time-right">
										<FirstTimeRightReport placeId={+placeId} />
									</Route>
									<Route path="/reports/:placeId?/overview">
										<StationOverview placeId={+placeId} />
									</Route>
									<Redirect to={`/reports/${placeId}/overview`} />
								</Switch>
							</Paper>
						)}
						{!!hasReportData && !loadingReport && (
							<Box mt={2}>
								<LogsTable logs={logs} />
								{Boolean(
									(page !== availablePages && availablePages !== 0) || loadingLogs
								) && (
									<Box mt={2} display="flex" justifyContent="center">
										<Button
											variant="contained"
											onClick={handleLoadMore}
											disabled={loadingLogs ? true : false}
										>
											{loadingLogs ? "Loading..." : "Load more logs"}
										</Button>
									</Box>
								)}
							</Box>
						)}
					</Container>
					<Box pt={20} />
				</Content>
			</Layout>
		</StationReportsProvider>
	);
};

export default function ReportsWrapped() {
	return (
		<DateRangeProvider initialValue={DATE_RANGE_FOR_THIS_WEEK}>
			<Reports />
		</DateRangeProvider>
	);
}
