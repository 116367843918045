export interface LabelInfo {
	url: string;
	title: string;
	subtitle?: string;
}

const template = (docs: LabelInfo[], labelsPerSheet: number = 21) => {
	const totalSheets = Math.ceil(docs.length / labelsPerSheet);
	const sheets = Array(totalSheets)
		.fill(undefined)
		.map((_, i) =>
			docs.slice(i * labelsPerSheet, i * labelsPerSheet + labelsPerSheet)
		);
	console.log(sheets);
	return `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Order Plate</title>
  
    <style>
      body, html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: sans-serif;
      }
  
      .page-root {
        position: relative;
        width: 8.5in;
        height: 11in;
      }
  
      .page-container {
        height: 100%;
        box-sizing: border-box;
        padding: 0.5in 0.21975in;
      }
  
      .page-header {
        margin-bottom: 0.35in;
      }
  
      .labels-container {
        line-height: 0;
        letter-spacing: 0;
      }
  
      .label-root {
        width: 2.5935in;
        height: 1in;
        box-sizing: border-box;
        padding: 0.035in;
        background: #ffffff;
        float: left;
        position: relative;
        line-height: 0;
        margin-right: 0.14in;
      }
  
      .label-root:nth-child(3n) {
        margin-right: 0;
      }
  
      .label-root > * {
        line-height: normal;
      }
  
      .label-root p {
        margin: 0 0 1em;
      }
  
      .label-root .qr-wrapper {
        float: left;
        height: 0.83in;
        line-height: 0;
        margin-right: 0.07in;
        box-sizing: border-box;
        padding: 0.07in;
      }
  
      .qr-wrapper img.qr-code {
        max-height: 100%;
      }
  
      .label-root .details-wrapper {
        padding-top: 0.07in;
      }
  
      .details-wrapper .details-header {
        margin-bottom: 0.07in;
      }
  
      .details-header .icon {
        display: inline-block;
        max-width: 24px;
        max-height: 24px;
        vertical-align: middle;
        margin-right: 0.035in;
      }
  
      .details-header p {
        display: inline;
      }
  
      .details-wrapper .details-info {
        overflow: hidden;
      }
  
      .details-info .title {
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 0.035in;
        /* white-space: nowrap; */
        overflow: hidden;
        /* text-overflow: ellipsis; */
      }
  
      .details-info p {
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
      }
  
      .label-root .label-footer {
        clear: both;
        margin: 0;
        font-size: 7px;
        letter-spacing: 0.15em;
        padding-left: 0.035in;
      }
    </style>
  </head>
  <body>
    ${sheets
					.map(
						sheet => `
    <div class="page-root">
      <div class="page-container">
        <header class="page-header">
          <table style="width: 100%;">
            <tr>
              <td>
                <h1 style="font-size: 2.5rem; text-align: center; margin-top: 0;">Shopping <span style="color: #eb0d2a">List</span></h1>
              </td>
              <td></td>
            </tr>
            <tr>
              <td width="65%" style="padding: 0 16px;">
                <div style="padding: 0.75in 0; text-align: center; background-color: #efefef; border: 2px dashed #aaaaaa; border-radius: 16px;">
                  <h2 style="color: #aaaaaa; font-weight: normal; font-size: 1.75rem;"><i>Place magnet here if it needs to be ordered</i></h2>
                </div>
              </td>
              <td>
                <div style="border: 2px solid #444444; margin-bottom: 8px; padding: 8px;">
                  <h3 style="margin: 0; font-size: 12px;">* A visible QR code means, we need to order.</h3>
                </div>
                <table style="border: 2px solid #444444; width: 100%; padding: 4px;">
                  <tr>
                    <td style="font-size: 12px; font-weight: 700;"><span style="background-color: #f0f000; display: inline-block; width: 16px; height: 16px; border-radius: 16px; vertical-align: text-bottom; margin-right: 8px;"></span><b>Ordered</b></td>
                    <td style="font-size: 12px; font-weight: 700;"><span style="background-color: #00f020; display: inline-block; width: 16px; height: 16px; border-radius: 16px; vertical-align: text-bottom; margin-right: 8px;"></span><b>Enough In Stock</b></td>
                  </tr>
                  <tr>
                    <td style="padding-right: 8px; vertical-align: top;">
                      <p style="margin-top: 0.5em;">Place yellow magnet over QR, if it has been ordered.</p>
                    </td>
                    <td style="vertical-align: top;">
                      <p style="margin-top: 0.5em;">We have enough in stock.</p>
                      <p>* Move it to the large square &lt;-- to signal "it needs to be ordered".</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </header>
  
        <!-- BEGIN: Labels container -->
        <div class="labels-container">
          ${sheet
											.map(
												label => `
          <!-- BEGIN: Label -->
          <div class="label-root">
            <div class="qr-wrapper">
              <img src="https://api.3hd.us/app/qrcode_gen.create?data=${
															label.url
														}" class="qr-code"/>
            </div>
            <div class="details-wrapper">
              <!-- <div class="details-header">
                <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg" class="icon">
              </div> -->
              <div class="details-info">
                <p class="title">${label.title}</p>
                <p>${label.subtitle || "--"}</p>
              </div>
            </div>
            <!-- <p class="label-footer">${label.url}</p> -->
          </div>
          <!-- END: Label -->
          `
											)
											.join("")}
        </div>
        <!-- END: Labels container -->
  
      </div>
    </div>
    `
					)
					.join("")}
  </body>
</html>  
`;
};

export default template;
