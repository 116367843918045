import { PlaceActivityLog } from "../../providers/PlaceReportProvider";

// function to open a new url in a new tab
export const openInNewTab = (url: string) => {
	if (typeof window === "object") window?.open(url, "_blank");
};

// function to get baseurl : @params: {isOrderTrack}
export const getBaseUrl = ({
	isOrderTrack = true,
}: {
	isOrderTrack?: boolean;
}) => {
	if (!isOrderTrack) {
		return process.env.NODE_ENV === "development"
			? window.location.hostname
			: window.location.href.includes("dev")
			? `https://dev.3hd.us/`
			: `https://3hd.us/`;
	} else {
		return process.env.NODE_ENV === "development"
			? window.location.hostname
			: window.location.href.includes("dev")
			? `https://productiontrack-dev.momenttrack.com/`
			: `https://productiontrack.momenttrack.com/`;
	}
};

export const groupLogsByDate = (logs: PlaceActivityLog[]) => {
	// this gives an object with dates as keys
	const groups = logs.reduce(
		(data: { [x: string]: PlaceActivityLog[] }, item) => {
			const date: any = item.arrived_at.split(" ")[0];
			if (!data[date]) {
				data[date] = [];
			}
			data[date].push(item);
			return data;
		},
		{}
	);

	// to add it in the array format instead
	return Object.keys(groups).map(date => {
		return {
			date,
			logs: groups[date],
		};
	});
};

export const generateThumbnailFromUrl = (videoUrl: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video");
		video.src = videoUrl;
		video.crossOrigin = "anonymous"; // Handle cross-origin requests
		video.muted = true; // Avoid autoplay issues
		video.playsInline = true; // For mobile compatibility

		video.addEventListener("loadeddata", () => {
			video.currentTime = video.duration / 2; // Seek to the middle of the video
		});

		video.addEventListener("seeked", () => {
			const canvas = document.createElement("canvas");
			canvas.width = video.videoWidth; // Match video dimensions
			canvas.height = video.videoHeight;
			const ctx = canvas.getContext("2d", { willReadFrequently: true });

			if (ctx) {
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
				const thumbnail = canvas.toDataURL("image/jpeg"); // Generate base64 thumbnail
				resolve(thumbnail);
			} else {
				reject(new Error("Failed to get canvas context"));
			}
			URL.revokeObjectURL(video.src); // Clean up
		});

		video.addEventListener("error", err => {
			reject(new Error(`Failed to load video: ${err.message}`));
		});
	});
};

export const generateThumbnailFromVideoFile = (
	videoFile: File
): Promise<File> => {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video");
		const url = URL.createObjectURL(videoFile);
		video.src = url;
		video.muted = true; // Avoid autoplay issues
		video.playsInline = true; // For mobile compatibility

		video.addEventListener("loadeddata", () => {
			video.currentTime = Math.min(video.duration / 3, 5); // Seek to the middle of the video
		});

		video.addEventListener("seeked", () => {
			const canvas = document.createElement("canvas");
			canvas.width = video.videoWidth; // Match video dimensions
			canvas.height = video.videoHeight;
			const ctx = canvas.getContext("2d", { willReadFrequently: true });

			if (ctx) {
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob(
					blob => {
						if (blob) {
							const file = new File([blob], "thumbnail.jpg", { type: "image/jpeg" });
							resolve(file);
						} else {
							reject(new Error("Failed to create blob from canvas"));
						}
					},
					"image/jpeg",
					0.9
				);
			} else {
				reject(new Error("Failed to get canvas context"));
			}

			URL.revokeObjectURL(url); // Clean up
		});

		video.addEventListener("error", err => {
			reject(new Error(`Failed to load video: ${err.message}`));
		});
	});
};

// function to parse string to array
export function parseStringToArray(input: string | any[]): any[] {
	// If input is already an array, return it directly
	if (Array.isArray(input)) {
		return input;
	}

	// Normalize the input by removing surrounding quotes if present
	if (typeof input === "string") {
		input = input.trim();

		// Check if it has surrounding quotes
		if (input.startsWith('"') && input.endsWith('"')) {
			// Remove the outermost quotes
			input = input.slice(1, -1);
		}

		// Unescape escaped double quotes for JSON strings
		input = input.replace(/\\"/g, '"');
	}

	// Parse the normalized string as JSON
	try {
		const parsedArray = JSON.parse(input);
		if (Array.isArray(parsedArray)) {
			return parsedArray;
		} else {
			throw new Error("Parsed value is not an array");
		}
	} catch (error) {
		console.error("Failed to parse input:", (error as Error).message);
		return [];
	}
}
