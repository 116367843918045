import { Slider, TextField } from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";

interface MinMaxFieldTypes {
	range: number[];
	setRange: Dispatch<SetStateAction<number[]>>;
	fixedMin: number;
	fixedMax: number;
	step: number;
	label: string;
}
export const formatNumber = (val: number) =>
	val !== null && val !== undefined
		? val.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
		  })
		: "";
export default function MinMaxField({
	range,
	setRange,
	fixedMin = -100,
	fixedMax,
	label,
	step,
}: MinMaxFieldTypes) {
	return (
		<div>
			<label htmlFor="range-slider">{label}</label>
			<Slider
				color="secondary"
				value={range}
				onChange={(_: any, newValue: number | number[]) => {
					setRange(newValue as number[]);
				}}
				min={fixedMin}
				max={fixedMax}
				step={step}
				valueLabelDisplay="auto"
				aria-label={label}
				aria-labelledby="range-slider"
				getAriaLabel={() => label}
			/>

			<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
				<TextField
					value={formatNumber(range[0])}
					onChange={e => {
						const inputValue = e.target.value.replace(/,/g, ""); // Remove formatting
						const numericValue = parseFloat(inputValue.replace(/,/g, "")); // Parse as a number

						if (!isNaN(numericValue) || inputValue === "") {
							// Update state with unformatted numeric value
							if (numericValue >= fixedMin && numericValue <= fixedMax - 5) {
								setRange([numericValue, range[1]]);
							} else {
								setRange([fixedMin, range[1]]);
							}
						}
					}}
					type={"text"}
					variant="outlined"
					color="secondary"
					size="small"
					style={{ flex: 1 }}
					InputProps={{
						inputProps: { min: fixedMin, max: fixedMax, step: step },
					}}
					required={false}
					placeholder={"0"}
				/>
				<span style={{ width: "10px", borderBottom: "1px solid black" }}></span>
				<TextField
					value={formatNumber(range[1])}
					onChange={e => {
						const inputValue = e.target.value.replace(/,/g, ""); // Remove formatting
						const numericValue = parseFloat(inputValue.replace(/,/g, "")); // Parse as a number

						if (!isNaN(numericValue) || inputValue === "") {
							// Update state with unformatted numeric value
							if (numericValue <= fixedMax) {
								setRange([range[0], numericValue || 0]);
							} else {
								setRange([range[0], fixedMax]);
							}
						}
					}}
					type={"text"}
					variant="outlined"
					color="secondary"
					size="small"
					style={{ flex: 1 }}
					InputProps={{
						inputProps: { min: fixedMin, max: fixedMax, step: step },
					}}
					required={false}
					placeholder={"0"}
				/>
			</div>
		</div>
	);
}
