import React, { useState, useMemo } from "react";
import { TrainingEditorProps } from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import StepsInput from "../StepsInput";
import {
	FileUploaderRef,
	VideoUploader,
} from "../../core/components/FileUploader";
import useNotify from "../../core/hooks/use-notify";
import randomize from "randomatic";
import {
	saveTraining,
	VisibilityType,
	generateSingleLabel,
	TrainingInputData,
} from "../../core/system/litmon";
import useTrainingInputForm from "./use-training-input-form";
import SingleTrainingLabelDialog from "../TrainingLabelsGeneratorDialog/SingleTrainingLabelDialog";
import { visit3hd } from "../../subapp/utils";
import { parseStringToArray } from "../../core/system/utils";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";

function getAlternateVideoUrl(url?: string) {
	if (typeof url !== "string" || !url.trim() || !url.includes("/uploads/"))
		return url;

	return url.replace("/uploads/", "/uploads_compressed/");
}

const renameFunc = (currName: string) => {
	const nameParts = currName.split(/[.|\s]+/g);
	const extension = nameParts.length <= 1 ? "" : nameParts.pop();

	// return randomize('a0', 6) + (!extension ? '' : `.${extension}`);

	nameParts.push(randomize("a0", 3));
	return nameParts.join("_") + (!extension ? "" : `.${extension}`);
};

const FILE_UPLOAD_URL = `${process.env.REACT_APP_ZETA_API}/generate/s3upload_url`;
const TRAINING_FILE_TYPES = [
	"mp4",
	"mpg",
	"mpeg",
	"wmv",
	"mov",
	"avi",
	"flv",
	"mkv",
	"webm",
];

const TrainingEditor: React.FC<TrainingEditorProps> = props => {
	const { docid, trainingDocument, onCancel = () => {}, type } = props;

	const videoUploaderRef = React.useRef<FileUploaderRef>(null);

	const notify = useNotify();

	const [visibility, setVisibility] = useState<VisibilityType>(
		trainingDocument?.visibility || "PUBLIC"
	);

	const [agreedToTerms, setAgreedToTerms] = useState(false);
	const [openLabelDialog, setopenLabelDialog] = useState(false);
	const [creatingLitmon, setcreatingLitmon] = useState(false);

	const [currentStatus, setcurrentStatus] = useState<string | null>(null);
	const [currUser] = useAuthentication();
	const [pdfUrl, setPdfUrl] = useState<string | null>(null);

	const handleGenerateLabel = async (title: string) => {
		try {
			const pdfUrl: string = await generateSingleLabel({
				title,
				docid,
			});
			setPdfUrl(pdfUrl);
		} catch (err) {
			alert((err as Error).message);
		}
	};

	const currTraining = useMemo(() => {
		if (
			!trainingDocument ||
			trainingDocument.organization_id !== currUser?.organization_id
		)
			return;

		return trainingDocument;
	}, [currUser?.organization_id, trainingDocument]);

	const trainingForm = useTrainingInputForm({
		initialValues: {
			...currTraining,
			instructions: JSON.stringify(currTraining?.instructions),
		},
		onSubmit: async values => {
			try {
				setcreatingLitmon(true);
				setcurrentStatus("Saving data");
				let res = await saveTraining(
					{
						...(values as TrainingInputData),
						doc_id: docid,
						visibility,
						pick_type: "TRAINING",
					},
					type
				);
				setcurrentStatus("Generating label");
				await handleGenerateLabel(res?.title);
				setopenLabelDialog(true);
				notify("Training video has been saved");
			} catch (err) {
				notify(err as Error);
			} finally {
				setcreatingLitmon(false);
				setcurrentStatus("");
			}
		},
	});

	const handleFormSave = async (ev: any) => {
		try {
			ev.preventDefault();

			if (type === "post" && !videoUploaderRef.current?.validate())
				throw new Error("Please select a training video.");
			else if (videoUploaderRef?.current?.hasFile()) {
				setcurrentStatus("Uploading video");
				setcreatingLitmon(true);
				const res = await videoUploaderRef!.current?.startUpload()!;

				trainingForm.setFieldValue("video_url", res?.file_url);
				trainingForm.setFieldValue("thumbnail_url", res?.thumbnail_url);
			}
			trainingForm.submitForm();
			setcreatingLitmon(false);
		} catch (err) {
			notify(err as Error);
			setcreatingLitmon(false);
		}
	};
	const isFormValid = useMemo(() => {
		return Boolean(trainingForm.values["title"]) && agreedToTerms;
	}, [agreedToTerms, trainingForm.values]);

	return (
		<form onSubmit={handleFormSave}>
			<Grid container spacing={4}>
				<Grid item xs={12} md={4} lg={3}>
					<Box mb={2}>
						<Typography variant="h6">Training title</Typography>
						<Typography variant="subtitle2" color="textSecondary">
							Give a title to this training.
						</Typography>

						<Box py={2}>
							<TextField
								placeholder="Learn how to?"
								{...trainingForm.getFieldProps("title")}
								disabled={creatingLitmon}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon>school</Icon>
										</InputAdornment>
									),
								}}
								error={!!trainingForm.errors["title"]}
								helperText={
									!!trainingForm.errors["title"] &&
									trainingForm.touched["title"] &&
									trainingForm.errors["title"]
								}
								multiline
								fullWidth
							/>
						</Box>
					</Box>
					<Box mb={2}>
						<Typography variant="h6">Company's email</Typography>
						<Typography variant="subtitle2" color="textSecondary">
							A copy of the generated certificate will be sent to this email as a CC
							(carbon copy).
						</Typography>

						<Box py={2}>
							<TextField
								type="email"
								placeholder="hr@company.com"
								{...trainingForm.getFieldProps("email")}
								disabled={creatingLitmon}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon>email</Icon>
										</InputAdornment>
									),
								}}
								required={false}
								error={!!trainingForm.errors["email"]}
								helperText={
									!!trainingForm.errors["email"] &&
									trainingForm.touched["email"] &&
									trainingForm.errors["email"]
								}
								multiline
								fullWidth
							/>
						</Box>
					</Box>

					<Box mb={2}>
						<Typography variant="h6">QR code label</Typography>
						<Typography variant="subtitle2" color="textSecondary">
							This training will be linked to following QR code.
						</Typography>

						<Box py={2}>
							<img
								alt="QR code"
								src={`https://api.3hd.us/app/qrcode_gen.create?data=${
									process.env.REACT_APP_QR_BASENAME || "3hd.us"
								}/${docid}`}
								style={{ maxWidth: "100%" }}
							/>
							<Typography
								component={"a"}
								onClick={() => visit3hd(`/${docid}`)}
								target="_blank"
								rel="noopener noreferrer"
								gutterBottom
								display="block"
								color="textSecondary"
							>
								{`${process.env.REACT_APP_QR_BASENAME || "3hd.us"}`}/...
								{docid.slice(-5)}
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="h6">Visibility</Typography>
						<Typography variant="subtitle2" color="textSecondary">
							Choose who can view this training by scanning the QR code.
						</Typography>

						<Box py={2}>
							<RadioGroup
								value={visibility}
								onChange={ev => setVisibility(ev.target.value as VisibilityType)}
							>
								<FormControlLabel
									label={
										<Typography>
											Public -{" "}
											<Typography variant="caption" color="textSecondary">
												Any one can view this training.
											</Typography>
										</Typography>
									}
									value="PUBLIC"
									disabled={creatingLitmon}
									control={<Radio />}
								/>
								<FormControlLabel
									label={
										<Typography>
											Internal -{" "}
											<Typography variant="caption" color="textSecondary">
												(This setting is under development) Only people within your company
												can view this training.
											</Typography>
										</Typography>
									}
									value="INTERNAL"
									disabled
									control={<Radio />}
								/>
							</RadioGroup>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md>
					<Grid container direction="column" spacing={2} wrap="nowrap">
						<Grid item>
							<Typography variant="h6" gutterBottom>
								Choose training video
							</Typography>
							{/* BEGIN: Video uploader */}
							<VideoUploader
								ref={videoUploaderRef}
								targetUrl={FILE_UPLOAD_URL as string}
								initialValue={getAlternateVideoUrl(
									currTraining?.video_url ||
										trainingForm?.getFieldProps("video_url")?.value
								)}
								rename={renameFunc}
								fileTypes={TRAINING_FILE_TYPES}
								maxFileSize={1048576 * 100} // 100MB
								required={type !== "edit"}
								disabled={creatingLitmon}
								// query={attachmentsConfig}
							/>
							{/* END: Video uploader */}
						</Grid>
						<Grid item>
							<Typography variant="h6" gutterBottom>
								Steps to remember
							</Typography>
							{(trainingForm?.values?.instructions?.length || 0) > 0 && (
								<Typography variant="body2" gutterBottom>
									Tip💡: To enter a url, use the format [text to show](url) e.g [click
									me](https://hello.com)
								</Typography>
							)}
							<Paper>
								<Box p={2}>
									<StepsInput
										value={parseStringToArray(
											trainingForm?.values["instructions"] || "[]"
										)}
										onChange={steps => trainingForm.setFieldValue("instructions", steps)}
										disabled={creatingLitmon}
									/>
								</Box>
							</Paper>
						</Grid>
						<Grid item>
							<FormControlLabel
								disabled={creatingLitmon}
								control={
									<Checkbox
										value={agreedToTerms}
										onClick={() => setAgreedToTerms(!agreedToTerms)}
									/>
								}
								label={
									<span>
										I agree to the{" "}
										<a
											style={{ color: "#ec1c24", textDecoration: "underline" }}
											href="https://momenttrackwebapp.s3.us-west-2.amazonaws.com/Moment+Track+terms+and+conditions+(1).pdf"
											target="_blank"
											rel="noopener noreferrer"
										>
											terms and conditions
										</a>{" "}
									</span>
								}
							/>
						</Grid>
						<Grid item>
							{onCancel && (
								<>
									<Button
										onClick={onCancel}
										variant="outlined"
										color="secondary"
										size="large"
									>
										Cancel
									</Button>
									&nbsp;&nbsp;&nbsp;
								</>
							)}
							<Button
								type="submit"
								size="large"
								variant="contained"
								color="secondary"
								startIcon={<Icon>save</Icon>}
								disabled={!isFormValid || creatingLitmon}
							>
								{creatingLitmon ? `${currentStatus}...` : "Save training"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<SingleTrainingLabelDialog
					docid={docid || ""}
					pdfUrl={pdfUrl || ""}
					open={openLabelDialog}
					onClose={(ev, reason) => {
						if (reason !== "backdropClick") {
							setopenLabelDialog(false);
							onCancel();
						}
					}}
					disableEscapeKeyDown
					maxWidth="sm"
					fullWidth
				/>
			</Grid>
		</form>
	);
};

export default TrainingEditor;
