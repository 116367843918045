import React, { useEffect } from "react";
import { DateRangePickerProps, DateRangeOptions } from "./types";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CustomRangePickerDialog from "./CustomRangePickerDialog";

import { useState, useCallback } from "react";
import { useDateRange } from "./DateRangeProvider";
import moment from "moment/moment";

const DateRangePicker: React.FC<DateRangePickerProps> = props => {
	const {
		options = ["today", "this-week", "this-month", "this-year", "reset"],
		asInput = false,
	} = props;

	const dateRange = useDateRange();
	const [label, setLabel] = useState("Select Date");
	const [custDialogOpen, setCustDialogOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	// Utility function to determine the range type
	const getInitialRangeType = useCallback(() => {
		const now = new Date();
		const startOfToday = moment(now).startOf("day").toDate();
		const endOfToday = moment(now).endOf("day").toDate();
		const startOfWeek = moment(now).startOf("week").toDate();
		const endOfWeek = moment(now).endOf("week").toDate();
		const startOfMonth = moment(now).startOf("month").toDate();
		const endOfMonth = moment(now).endOf("month").toDate();
		const startOfYear = moment(now).startOf("year").toDate();
		const endOfYear = moment(now).endOf("year").toDate();

		const from = dateRange?.from ? new Date(dateRange.from) : undefined;
		const to = dateRange?.to ? new Date(dateRange.to) : undefined;

		if (!from || !to) return "reset";

		if (from >= startOfToday && to <= endOfToday) return "today";
		if (from >= startOfWeek && to <= endOfWeek) return "this-week";
		if (from >= startOfMonth && to <= endOfMonth) return "this-month";
		if (from >= startOfYear && to <= endOfYear) return "this-year";

		return "custom";
	}, [dateRange]);

	const handleChange = useCallback(
		(rangeType: DateRangeOptions) => {
			let from: Date | undefined = new Date();
			let to: Date | undefined = new Date();

			if (rangeType !== "reset") {
				// Fix time component from the range. Set 12am for "from" date and 11:59pm for "to" date.
				from = new Date(from.toDateString());
				to = new Date(to.toDateString());
				to.setDate(to.getDate() + 1);
				to.setMilliseconds(-1);
			}

			switch (rangeType) {
				case "this-week":
					from.setDate(from.getDate() - from.getDay());
					setLabel(
						`This Week | ${moment(from).format("MMM DD")} - ${moment(to).format(
							"MMM DD"
						)}`
					);
					break;
				case "this-month":
					from.setDate(1);
					setLabel(
						`This Month | ${moment(from).format("MMM DD")} - ${moment(to).format(
							"MMM DD"
						)}`
					);
					break;
				case "this-year":
					from.setMonth(0);
					from.setDate(1);
					setLabel(
						`This Year | ${moment(from).format("MMM DD")} - ${moment(to).format(
							"MMM DD"
						)}`
					);
					break;
				case "today":
					setLabel(
						`Today | ${moment(from).format("hh:mm A")} - ${moment(to).format(
							"hh:mm A"
						)}`
					);
					break;
				case "reset":
				default:
					from = undefined;
					to = undefined;
					setLabel("Reset");
					break;
			}

			dateRange.update(from, to);
			setAnchorEl(null);
		},
		[dateRange]
	);

	useEffect(() => {
		const rangeType = getInitialRangeType();

		switch (rangeType) {
			case "today":
				setLabel(
					`Today | ${moment(dateRange.from).format("hh:mm A")} - ${moment(
						dateRange.to
					).format("hh:mm A")}`
				);
				break;
			case "this-week":
				setLabel(
					`This Week | ${moment(dateRange.from).format("MMM DD")} - ${moment(
						dateRange.to
					).format("MMM DD")}`
				);
				break;
			case "this-month":
				setLabel(
					`This Month | ${moment(dateRange.from).format("MMM DD")} - ${moment(
						dateRange.to
					).format("MMM DD")}`
				);
				break;
			case "this-year":
				setLabel(
					`This Year | ${moment(dateRange.from).format("MMM DD")} - ${moment(
						dateRange.to
					).format("MMM DD")}`
				);
				break;
			case "reset":
				setLabel("Reset");
				break;
			case "custom":
			default:
				setLabel(
					`${moment(dateRange.from).format("MMM DD, YYYY")} - ${moment(
						dateRange.to
					).format("MMM DD, YYYY")}`
				);
				break;
		}
	}, [dateRange.from, dateRange.to, getInitialRangeType]);

	return (
		<>
			<ButtonGroup
				style={{
					width: asInput ? "100%" : "unset",
					height: asInput ? 56 : "unset",
				}}
				variant={asInput ? "outlined" : "contained"}
				color={asInput ? "default" : "secondary"}
			>
				<Tooltip
					title={`${moment(dateRange?.from).format(
						"YYYY/MM/DD hh:mm a"
					)} -- ${moment(dateRange?.to).format("YYYY/MM/DD hh:mm a")}`}
				>
					<Button
						startIcon={<DateRangeIcon />}
						style={{
							textTransform: "initial",
							width: asInput ? "100%" : "unset",
							justifyContent: asInput ? "flex-start" : "center",
						}}
						onClick={() => setCustDialogOpen(true)}
					>
						{label}
					</Button>
				</Tooltip>
				<Button onClick={ev => setAnchorEl(ev.currentTarget)} size="small">
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={!!anchorEl}
				onClose={() => setAnchorEl(null)}
			>
				{options.length &&
					options.map((op, i) => (
						<MenuItem
							key={`${op}-${i}`}
							style={{ textTransform: "capitalize" }}
							onClick={() => handleChange(op)}
						>
							{op.replace("-", " ")}
						</MenuItem>
					))}
			</Menu>

			<CustomRangePickerDialog
				open={custDialogOpen}
				initialValues={dateRange}
				onDone={range => {
					dateRange.update(range?.from, range?.to);
					setLabel(
						`${moment(range?.from).format("MMM DD, YYYY")} - ${moment(
							range?.to
						).format("MMM DD, YYYY")}`
					);
					setCustDialogOpen(false);
				}}
				onCancel={() => setCustDialogOpen(false)}
			/>
		</>
	);
};

export default DateRangePicker;
