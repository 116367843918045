import React, {
	useState,
	useImperativeHandle,
	forwardRef,
	useEffect,
	useRef,
	MutableRefObject,
	useMemo,
} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
	ITemplateFields,
	ProductionOrder,
	editOrder,
	generateActivitySheets,
} from "../../core/system/production-orders";
import { OrderTemplateDialogComponent } from "./types";
import {
	TextField,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Select,
	MenuItem,
	// Checkbox,
	Divider,
	InputLabel,
	Typography,
	Link,
	Card,
	CardHeader,
	Icon,
	CardContent,
	FormLabel,
} from "@material-ui/core";
import "./style.css";
import useNotify from "../../core/hooks/use-notify";
import TruckLoader from "../../core/components/TruckLoader";
import EmbeddedContentDialog, {
	EmbeddedContentDialogApi,
} from "../../core/components/EmbeddedContentDialog";
import { visit3hd } from "../../subapp/utils";

const OrderTemplateModal: OrderTemplateDialogComponent = (props, ref) => {
	const [loading, setLoading] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [order, setOrder] = useState<ProductionOrder | null>(null);
	const [order_template, setorder_template] = useState<ITemplateFields | null>();
	const [formValues, setFormValues] = useState<{ [key: string]: string } | null>(
		null
	);
	const notify = useNotify();

	// Memoize the sorted order template to prevent unnecessary updates
	const sortedOrderTemplate = useMemo(() => {
		return order_template
			? Object.entries(order_template).sort(([, a], [, b]) => a.order - b.order)
			: [];
	}, [order_template]);

	useEffect(() => {
		if (sortedOrderTemplate.length > 0 && order) {
			// Map sorted template entries to form values
			const sortedTemplate = sortedOrderTemplate.map(([key, field]) => {
				const findFormField = order?.order_template
					? order.order_template[key]
					: "";

				// Assign default values based on conditions
				if (field.default_value && findFormField === "") {
					const defaultValueKey = field.default_value as keyof ProductionOrder;
					return [
						key,
						String(
							order && order.hasOwnProperty(defaultValueKey)
								? order[defaultValueKey]
								: field.default_value
						),
					];
				} else if (field.type === "number" && findFormField === "") {
					return [key, "0"];
				} else if (field.type === "date" && findFormField === "") {
					return [key, new Date().toISOString().split("T")[0]];
				} else if (findFormField !== "") {
					return [key, findFormField];
				} else {
					return [key, field.value || ""];
				}
			});

			// Convert sorted array to object and update state
			const sortedObj: { [key: string]: string } =
				Object.fromEntries(sortedTemplate);
			setFormValues(sortedObj);
		}
	}, [order, sortedOrderTemplate]); // Use the memoized sortedOrderTemplate as a dependency
	const onFieldChange = (name: string, value: string) => {
		setFormValues(prev => {
			if (prev) return { ...prev, [name]: value };
			else return prev;
		});
	};

	const areAllFormFilled = useMemo(() => {
		if (formValues && order_template) {
			return Object.entries(order_template).every(([key, field]) => {
				if (!field.required) {
					return true;
				} else {
					if (typeof formValues[key] === "boolean") {
						return true;
					} else {
						return Boolean(formValues[key]);
					}
				}
			});
		}
	}, [formValues, order_template]);

	const FIELDS: {
		[key: string]: (
			label: string,
			name: string,
			options?: string[]
		) => JSX.Element;
	} = {
		textbox: (label: string, name: string) => (
			<TextField
				variant="outlined"
				fullWidth
				color="secondary"
				label={label}
				type="text"
				className="input-field"
				value={formValues && formValues[name]}
				disabled={loading}
				onChange={ev => onFieldChange(name, ev.target.value)}
				placeholder={`Enter ${label}`}
				inputProps={{
					tabIndex: 8,
					height: "45px",
					type: "text",
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		),
		number: (label: string, name: string) => (
			<TextField
				variant="outlined"
				fullWidth
				color="secondary"
				label={label}
				type="number"
				className="input-field"
				defaultValue={0}
				value={formValues && formValues[name]}
				disabled={loading}
				onChange={ev => onFieldChange(name, ev.target.value)}
				placeholder={`Enter ${label}`}
				inputProps={{
					tabIndex: 8,
					height: "45px",
					type: "number",
					min: 0,
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		),
		date: (label: string, name: string) => (
			<TextField
				variant="outlined"
				fullWidth
				color="secondary"
				label={label}
				type="date"
				className="input-field"
				value={formValues && formValues[name]}
				disabled={loading}
				onChange={ev => onFieldChange(name, ev.target.value)}
				inputProps={{
					tabIndex: 8,
					height: "45px",
					type: "date",
				}}
				InputLabelProps={{
					shrink: true,
				}}
			/>
		),
		radio: (label: string, name: string, options?: string[]) => (
			<FormControl style={{ marginTop: "0.6rem" }} fullWidth>
				<RadioGroup
					value={formValues ? formValues[name] : undefined}
					onChange={(ev, val: string) => {
						onFieldChange(name, val);
					}}
					row
				>
					{options &&
						options.map(el => (
							<FormControlLabel
								value={el}
								label={el}
								key={el}
								disabled={loading}
								control={<Radio />}
							/>
						))}
				</RadioGroup>
			</FormControl>
		),
		select: (label: string, name: string, options?: string[]) => (
			<FormControl variant="outlined" className="input-field">
				<InputLabel
					style={{ background: "white", padding: "0 0.4rem" }}
					shrink={true}
					color="secondary"
					id={name}
				>
					{label}
				</InputLabel>
				<Select
					fullWidth
					color="secondary"
					labelId={name}
					value={formValues ? formValues[name] : undefined}
					onChange={ev => {
						onFieldChange(name, ev.target.value as string);
					}}
					displayEmpty
					disabled={loading}
				>
					<MenuItem value={""}>None</MenuItem>
					{options &&
						options.map(el => (
							<MenuItem key={el} value={el}>
								{el}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		),
		checkbox: (label: string, name: string) => (
			<FormControl
				className="input-field"
				style={{ marginTop: "0.6rem" }}
				fullWidth
			>
				<FormLabel component="legend">{label}</FormLabel>
				<RadioGroup
					value={formValues ? formValues[name] : undefined}
					onChange={(ev, val: string) => {
						onFieldChange(name, val);
					}}
					row
				>
					<FormControlLabel
						value={true}
						label={"Yes"}
						disabled={loading}
						control={
							<Radio
								checked={formValues ? formValues[name].toString() === "true" : false}
							/>
						}
					/>
					<FormControlLabel
						value={false}
						label={"No"}
						disabled={loading}
						control={
							<Radio
								checked={formValues ? formValues[name].toString() === "false" : false}
							/>
						}
					/>
				</RadioGroup>
			</FormControl>
		),
	};

	const closeModal = () => {
		labelDialog.current?.closeDialog();
		setDialogOpen(false);

		if (props.onClose) {
			props.onClose();
		}
	};

	useImperativeHandle(
		ref,
		() => {
			return {
				openDialog: (order, template) => {
					setOrder(order);
					setorder_template(template);
					setDialogOpen(!!order);
				},
			};
		},
		[]
	);

	const formRef = useRef() as MutableRefObject<HTMLDivElement>;

	const labelDialog = useRef<EmbeddedContentDialogApi>(null);

	return (
		<>
			<Dialog open={dialogOpen} onClose={closeModal} fullScreen>
				{loading && <TruckLoader />}
				<DialogTitle>Edit Order Details</DialogTitle>
				<Divider />
				<DialogContent ref={formRef}>
					<form
						onSubmit={async ev => {
							ev.preventDefault();
							if (formValues && order) {
								setLoading(true);
								try {
									await editOrder(order?.id, {
										order_template: formValues,
									});
									notify("Order details updated successfully");
									const tabSeparatedValues = Object.entries(formValues)
										.map(([key, item]) => {
											if (item.toString() === "true" || item.toString() === "false") {
												return "%09";
											} else if (
												order_template ? order_template[key].type === "select" : false
											) {
												return `${item}%0D%0A`;
											} else return item;
										})
										.join("%09");
									labelDialog.current?.openDialog(() =>
										generateActivitySheets(
											[order],
											"order-template",
											`${tabSeparatedValues}`
										)
									);
								} catch (error) {
									notify(error as Error);
								} finally {
									setLoading(false);
								}
							}
						}}
					>
						{order && (
							<Card
								style={{
									maxWidth: 700,
									margin: "1rem auto 0",
									background: "rgb(245, 245, 245)",
									padding: "1rem",
									borderRadius: 8,
								}}
							>
								<CardHeader
									style={{
										padding: "0 0 0.5rem",
										borderBottom: "1px solid rgba(0, 0, 0, 0.53)",
									}}
									avatar={<Icon>inventory</Icon>}
									title={order.product.part_number}
									subheader={order.product.description}
									titleTypographyProps={{ variant: "h6" }}
								/>
								<CardContent style={{ padding: "0.5rem 0 0" }}>
									<Typography variant="subtitle1">
										Quantity: {order.requested_qty}
									</Typography>
									<Typography variant="subtitle1">
										Work Order: {order.external_docid}
									</Typography>
								</CardContent>
							</Card>
						)}

						<div className="order-template-form">
							<Typography
								variant="body1"
								style={{ width: "100%", marginBottom: "0rem" }}
							>
								Fill in the form below to edit order template
							</Typography>
							{sortedOrderTemplate &&
								sortedOrderTemplate.map(([key, value]) =>
									FIELDS[value.type]
										? FIELDS[value.type](value.label, key, value?.options)
										: null
								)}
						</div>
						<DialogActions className="dialog-footer">
							<Button
								variant="outlined"
								type="button"
								color="secondary"
								onClick={() => {
									closeModal();
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="secondary"
								type="submit"
								disabled={!areAllFormFilled || loading}
							>
								{loading ? "Loading..." : "Submit"}
							</Button>
						</DialogActions>
					</form>
				</DialogContent>
			</Dialog>

			<EmbeddedContentDialog
				dialogFooter={
					<Link onClick={() => visit3hd(`/${order?.docid}`)}>
						<Button
							variant="contained"
							type="button"
							color="secondary"
							onClick={() => {
								closeModal();
							}}
						>
							View Order
						</Button>
					</Link>
				}
				ref={labelDialog}
				title="Order Details Saved"
				onCloseDialog={() => closeModal()}
			/>
		</>
	);
};
export default forwardRef(OrderTemplateModal);
