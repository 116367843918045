import axios from "axios";
import { AuthData } from "./types";
import { subscribe, publish } from "../pubsub";

const client = axios.create();

let authData: AuthData = {
	access_token: "",
	refresh_token: "",
	org_slug: "default",
};

function updateAuth(data: AuthData | null) {
	Object.assign(
		authData,
		data || {
			access_token: "",
			refresh_token: "",
			org_slug: "default",
		}
	);

	client.defaults.baseURL = `${process.env.REACT_APP_ORDERTRACK_API_HOST}/api/${authData.org_slug}`;
	client.defaults.headers.common[
		"Authorization"
	] = `Bearer ${authData.access_token}`;
}

subscribe<AuthData>(
	"update:authentication",
	({ data, meta = {} }) => meta.trigger !== "refresh-token" && updateAuth(data)
);

client.interceptors.response.use(undefined, err => {
	const originalReq = err.config;
	if (!err.response)
		throw new Error(
			"Couldn't reach out to the server. Check your connection and reload this page."
		);
	else if (
		err.response.status === 401 ||
		(err.response.status === 403 && authData.refresh_token)
	) {
		originalReq.__retry = true;
		const refreshTokenUrl = `${process.env.REACT_APP_ORDERTRACK_API_HOST}/auth/default/system/refresh`;
		return axios
			.post(refreshTokenUrl, undefined, {
				headers: { Authorization: `Bearer ${authData.refresh_token}` },
			})
			.then(res => {
				updateAuth(res.data);
				publish("update:authentication", Object.assign(authData, res.data), {
					trigger: "refresh-token",
				});
				const headers = originalReq.headers;
				delete headers["Authorization"];
				return client(originalReq);
			})
			.catch(err => {
				updateAuth(null);
				publish("update:authentication", null, { trigger: "refresh-token" });
				throw new Error("Session expired.");
			});
	}
	return Promise.reject(new Error(err.response.data.message));
});

export default client;
