import React, { ReactNode, useMemo } from "react";
import { Avatar, Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

export interface EmptyStateProps {
	icon: ReactNode | React.ElementType; // Allows React components or nodes
	iconSize?: number;
	title?: string;
	subTitle?: string;
	btnAction?: (args?: unknown) => void;
	btnLink?: string;
	btnText?: string;
}

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		margin: theme.spacing(10, "auto"),
		gap: theme.spacing(2),
	},
	avatar: {
		backgroundColor: "#ffafb16b",
		width: 80,
		height: 80,
	},
	title: {
		fontSize: "1.5rem",
		marginBottom: theme.spacing(1),
		fontWeight: 600,
	},
	subTitle: {
		color: theme.palette.text.secondary,
		fontSize: "0.875rem",
	},
	button: {
		minWidth: 168,
	},
}));

export default function EmptyState({
	icon,
	iconSize = 40,
	title,
	subTitle,
	btnLink,
	btnAction = () => {},
	btnText,
}: EmptyStateProps) {
	const classes = useStyles();

	// Memoize icon rendering to avoid recalculations on re-render if icon doesn't change
	const IconElement = useMemo(() => {
		if (!icon) return null;

		// Return as-is if it's already a ReactNode
		return icon as ReactNode;
	}, [icon]);

	return (
		<div className={classes.root}>
			<Avatar className={classes.avatar}>{IconElement}</Avatar>
			<div>
				{title && <p className={classes.title}>{title}</p>}
				{subTitle && <p className={classes.subTitle}>{subTitle}</p>}
			</div>
			{btnLink ? (
				<Button
					className={classes.button}
					component={Link}
					to={btnLink}
					variant="contained"
					color="primary"
				>
					{btnText}
				</Button>
			) : (
				btnText && (
					<Button
						className={classes.button}
						onClick={btnAction}
						variant="contained"
						color="primary"
					>
						{btnText}
					</Button>
				)
			)}
		</div>
	);
}
