import client from "../../system/ordertrack-client/client";

interface BodyType {
	email: string;
	// Add more properties or make them optional as needed
}

export const sendCSVMail = async <T extends BodyType>(
	type: string,
	body: T
): Promise<{ data: { task_id: string }; message: string }> => {
	const res = await client.post(`${type}`, body);
	return res.data;
};
