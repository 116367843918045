import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonForm from "../../core/forms/PersonForm";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";

const ProfileSettings: React.FC = () => {
	const [currPerson, loading, { updateProfile }] = useAuthentication();

	return (
		<>
			<Box p={2}>
				<Typography variant="h6">Change profile information</Typography>
			</Box>
			<Box p={2}>
				<Grid container>
					<Grid item xs={12} md={6} lg={4}>
						<PersonForm
							initialValues={currPerson || undefined}
							onSubmit={updateProfile}
							disabled={loading}
						>
							{!loading && (
								<Button
									type="submit"
									disabled={loading}
									variant="contained"
									color="secondary"
								>
									Save
								</Button>
							)}
							{loading && <CircularProgress size={24} />}
						</PersonForm>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default ProfileSettings;
