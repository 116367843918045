import randomize from "randomatic";
import template from "./pdf-templates/order-plate";
import { Bin, BinFamily } from "../bin-families";
import html2Pdf, { PdfOptions } from "../utils/html-to-pdf";
import { generateUrl } from "../utils/parse-momenttrack-id";

class OrderPlate {
	binFamilies: BinFamily[];

	constructor(data: { binFamilies: BinFamily[]; bins: Bin[] }) {
		this.binFamilies = data.binFamilies;
	}

	async getPdf() {
		let labelsData: any = [];

		this.binFamilies.forEach(binFamily => {
			const binIds = binFamily.bins;
			if (!binIds) return;

			binIds.forEach((binId, i) => {
				if (i === 1) return; // Skip the second bin

				labelsData.push({
					url: generateUrl(binId, "document"),
					title: binFamily.product.name,
					subtitle: binFamily.product.part_number,
				});
			});
		});

		const requestData: PdfOptions = {
			pdf_mode: "portrait",
			pdf_name: `order-plate-${randomize("a0", 6)}`,
			page_size: "Letter",
			margin_top: "0mm",
			margin_bottom: "0mm",
			margin_left: "0mm",
			margin_right: "0mm",
			disable_smart_shrinking: null,
		};

		const response = await html2Pdf(template(labelsData, 21), requestData);

		// await momentTrackService.printPdf(response.data.data?.s3_path, 'Bin family labels from ordertrack');
		return response;
	}
}

export default OrderPlate;
